<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12 col-sm-12 " style="margin-top: -3px;">
        <div class="card">
          <div class="card-body">
              <div class="row justify-content-md-center">
                <div class="pt-4 col-12">
                  <div class="titulo text-center">
                    <h2>FAQ</h2>
                  </div>
                  <h6 class="text-center" style="line-height: 1.5em">
                    Aqui você encontra as principais dúvidas e respostas que você precisa para realizar sua pré-reserva.<br/>
                    Caso você tenha alguma dúvida que não se encontra aqui, estamos disponíveis via Whatsapp para te ajudar em todo processo.</h6>
                </div>
                <div class="col-12 col-md-5 pt-4">
                  <div class="form-group">
                    <div class="input-group-icon input-group-lg input-group-icon-left input-group-icon-right">
                      <input v-model="searchTerm" @input="onSearchTermInput" class="form-control form-control-rounded" type="text" placeholder="Pesquisar">
                      <span class="pt-2 font-20 ti ti-search input-icon input-icon-left"></span>
                      <span  v-on:click="clearSearchTerm" v-if="searchTerm" class="pt-2 font-20 ti ti-close input-icon input-icon-right"></span>
                    </div>
                  </div>
                </div>
                <div class="col-12 perguntas">
                  <div style="max-width: 100%;">
                    <h3 class="primary pt-4" style="font-size:23px; margin-bottom:5px;" v-if="filteredQuestionListComputed.length > 0">PERGUNTAS FREQUENTES</h3>
                    <app-accordion-index class="portal" v-for="(faq, index) in filteredQuestionListComputed" v-bind:key="index">
                      <template v-slot:title class="click">
                        <div style="display: flex;align-items: center;justify-content: space-between;">
                          <span class="font-semibold text-xl secondary secondary"  style="color: #3D3D3D);">{{ faq.id }}. {{ faq.question }}</span>
                          <div class="plus">
                            <span class="material-icons-outlined" style="color: #3D3D3D;">add</span>
                          </div>
                        </div>
                      </template>
                      <template v-slot:content style="z-index: 99">
                        <p class="paragraph" v-html="faq.answer"></p>
                      </template>
                    </app-accordion-index>

                    <div class="col-12 m-4  pb-5" v-if="filteredQuestionListComputed.length <= 0">
                      <div class="float-left" style="width: 100px;">
                        <img src="/static/images/search_icon.png" alt="">
                      </div>
                      <div class="float-left text-content">
                        <div class="mt-1 mb-2">Ops! Nenhuma pergunta ou resposta com o termo buscado.</div>
                        <div class="mb-3">
                          Caso tenha alguma dúvida você pode conversar conosco através do Whatsapp <a target="_blank" href="https://web.whatsapp.com/send?phone=5547991696700" class="desktop">
                          +55 (47) 99169-6700
                        </a>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'
import AppAccordionIndex from '@/components/AppAccordionIndex'

export default {
  name: 'PortalFaq',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('FAQ') + ' - %s'
    }
  },
  data () {
    return {
      timer: 0,
      searchTerm: '',
      isLoading: false,
      fullPage: true,
      filteredQuestionList: [],
      questionList: [
        {
          id: 1,
          question: 'A Royal cota com todos os armadores?',
          answer: 'Cotamos com todos os armadores disponíveis em sua rota escolhida.'
        },
        {
          id: 2,
          question: 'Como saberei qual é o armador da minha cotação?',
          answer: 'Após a pré-reserva realizada, em nosso primeiro contato você já recebe esta e mais informações referentes ao seu armador.'
        },
        {
          id: 3,
          question: 'Em quanto tempo recebo a confirmação de pré-reserva e informações de pagamento?',
          answer: 'Após pré-reserva, nossa equipe entrará em contato com você dentro de 24 horas comerciais com todas as informações necessárias.'
        },
        // {
        //   id: 4,
        //   question: 'Como faço para não relacionar um armador que não quero trabalhar?',
        //   answer: 'Temos um campo especial disponibilizado em uma das etapas que você passa.'
        // },
        {
          id: 4,
          question: 'As taxas de cancelamento, como são para o fechamento na ferramenta?',
          answer: 'As taxas de cancelamento estão sujeitas a taxação com estipulação do armador.'
        },
        {
          id: 5,
          question: 'Quais os próximos passos após o fechamento de pré-reserva pelo portal?',
          answer: 'Após realizar a pré-reserva de sua carga você receberá um e-mail com todos os dados preenchidos e confirmação que sua pré-reserva foi efetuada. Após isto é só aguardar que nosso time entrará em contato com você nas próximas 24 horas comerciais.'
        },
        {
          id: 6,
          question: 'É Booking confirmado?',
          answer: 'O processo em nosso site é garantia de pré-reserva de sua carga. Após realizada entraremos em contato com você para formalizar todo o procedimento.'
        },
        {
          id: 7,
          question: 'Quando receberei o Booking?',
          answer: 'Após realizar a pré-reserva em nosso portal, nosso  time entrará em contato com você com o andamento de seu processo.'
        },
        {
          id: 8,
          question: 'Caso eu perca o prazo de pagamento, consigo recuperar a mesma tarifa?',
          answer: 'Não. Nosso sistema de cotações usa dados e valores atuais que você deve estar ciente que estão sendo garantidos naquele momento até o momento de efetivação de sua reserva.'
        },
        {
          id: 9,
          question: 'Quando o cliente recebe as informações de booking?',
          answer: 'Quando o cliente recebe as informações de booking?'
        },
        {
          id: 10,
          question: 'Como ele pode tornar a compra recorrente?',
          answer: 'Após o contato com nosso operacional, informe para o mesmo seu interesse em manter esta compra como recorrente.'
        },
        {
          id: 11,
          question: 'Qual prazo de pagamento?',
          answer: 'Por acordo padrão, o pagamento sobre exportação é realizado na data de embarque da carga e Importação quando a carga desembarca no destino.'
        },
        {
          id: 12,
          question: 'Qual forma de pagamento para clientes novos? ',
          answer: 'Via depósito em conta.'
        },
        {
          id: 13,
          question: 'Existe alguma forma de pagamento diferenciada ou desconto para clientes recorrentes?',
          answer: 'Após realizar a pré-reserva, converse com o responsável por sua conta para descobrir as possibilidades.'
        },
        {
          id: 14,
          question: 'Como posso corrigir uma informação que preenchi errada?',
          answer: 'Após efetuar a pré-reserva, nossa equipe entrará em contato e você terá a oportunidade de comunicar alguma informação preenchida incorretamente.'
        },
        {
          id: 15,
          question: 'Quero negociar a proposta, quem devo contatar?',
          answer: 'Consulte nosso atendimento online via Whatsapp para discutir propostas.'
        },
        {
          id: 16,
          question: 'Quais as próximas saídas do armador?',
          answer: 'A data de saída (Departure) será entre o período informado na sua cotação.<br/><br/>' +
            'Você será informado das datas disponíveis após o primeiro contato de nosso time operacional após sua confirmação de pré-reserva.'
        },
        {
          id: 17,
          question: ' Qual o terminal de desconsolidação? ',
          answer: 'Para processos <strong>LCL</strong> temos os seguintes: ' +
            'CRAFT - MULTILOG <br/>' +
            'ALLINK - APM <br/>' +
            'WF - LocalFrio <br/>' +
            'MSL – Forte <br/>' +
            'POLY - POLY<br/><br/>' +
            'Para demais Portos/Cidades, nosso time comercial entrará em contato com você após reserva para verificar todas as possibilidades.'
        },
        {
          id: 18,
          question: 'Qual o valor das taxas de cancelamento/amend?',
          answer: 'Os custos de cancelamento estão sujeitos a aplicação conforme a cobrança do armador.'
        }
      ]
    }
  },
  components: {
    Loading,
    AppAccordionIndex
  },
  // Apply filters to local filter
  created () {
    this.filteredQuestionList = [...this.questionList]
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  props: {
    delay: {
      required: false,
      type: Number,
      default: 400
    }
  },
  methods: {
    clearSearchTerm () {
      this.searchTerm = ''
      this.onSearchTermInput()
    },
    onSearchTermInput () {
      let _this = this
      let searchTerm = _this.searchTerm

      if (searchTerm.length > 0) {
        // Considerando delay para não fazer multiplas buscas
        window.clearTimeout(this.timer)

        _this.timer = window.setTimeout(function () {
          _this.isLoading = true
          let search = _this.$util.slugify(searchTerm.toLowerCase())
          _this.filteredQuestionList = _this.questionList.filter((el) => {
            let question = _this.$util.slugify(el.question.toLowerCase())
            let answer = _this.$util.slugify(el.answer.toLowerCase())

            return (answer.indexOf(search) > -1 || question.indexOf(search) > -1)
          })

          _this.isLoading = false
        }, this.delay)
      } else {
        _this.filteredQuestionList = [..._this.questionList]
      }
    }
  },
  computed: {
    filteredQuestionListComputed () {
      return this.filteredQuestionList
    },
    process () {
      return this.$route.params.process || null
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    process: function () {
      this.filter.internal_code = this.$route.params.process
      this.filters.filProcesso = this.$route.params.process
      this.$refs.internal_code.value = this.$route.params.process

      this.applyFilter()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }

  .portal .title{
    border-color: #e5e5e5;
  }

  .portal .content{
    background: inherit;
    padding-left: 0;
    padding-right: 0;
  }

  .portal .title[aria-expanded="true"] {
    background: rgba(141, 171, 196, 0.09);
    color: rgb(51, 51, 51);
  }

</style>
